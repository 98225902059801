import pro1 from "../assets/project1.png";
import pro2 from "../assets/project2.png";
import pro3 from "../assets/project3.png";

const ProjectCardData = [
    {
        imgsrc: pro1,
        title:"Ecommerce Website Design",
        text:"Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of Wow! I really can do this for over 10 online entrepreneurs, Best-selling marketing courses",
        view: "LINK DO PROJETO AQUI",
        source: "Source do projeto aQUI",
    },

    {
        imgsrc: pro2,
        title: "Ecommerce Website Design",
        text: "Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of Wow! I really can do this for over 100,000 online entrepreneurs. Best-selling marketing courses",
        view: "LINK DO PROJETO AQUI",
        source: "Source do projeto aQUI",
    },

    {
        imgsrc: pro3,
        title: "Ecommerce Website Design",
        text: "Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of Wow! I really can do this for over 100,000 online entrepreneurs. Best-selling marketing courses",
        view: "LINK DO PROJETO AQUI",
        source: "Source do projeto aQUI",
    },
]

export default ProjectCardData